<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">審査 - 入力</p>
        <hr class="title">
      </div>
      <div class="mt-4">
        <div class="bold">審査するプログラムの項目を選択してください</div>
        <div class="search-area">
          <div class="flex flexCenter">
            <div class="search-middle inlineBlock search-space">
              <div>年度</div>
              <div>
                <b-form-select
                  :value="search.year"
                  @input="resetFilter(apptype=true, stage=true);setFilter('year', $event)"
                  :options="yearList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
                </b-form-select>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>プログラム名</div>
              <div>
                <b-form-select
                  :value="search.apptype"
                  @input="resetFilter(apptype=false, stage=true);setFilter('apptype', $event);fetchAppList()"
                  :options.sync="apptypeList">
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>審査段階</div>
              <div>
                <b-form-select
                  @input="resetFilter(apptype=false, stage=false);
                    setFilter('stageId', $event);fetchAppList()"
                  :value="search.stageId"
                  :options.sync="stageList">
                  <template v-slot:first>
                    <option :value="null">
                      {{defaultStageText}}
                    </option>
                  </template>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
        <p
          class="mb-1"
          v-if="isOutTerm">
          審査期間外のため、審査はできません。審査期間は{{outTermText.from}}から{{outTermText.to}}までです。
        </p>
        <p
          v-if="items[constEval.CHECK] && items[constEval.CHECK].length > 0"
          class="mb-1">利害関係がある場合評価しないでください。</p>
      </div>


      <div>
        <div
          v-show="!isOverView"
          @click="changeAllOverView(true)"
          class="mode-switch-btn on">全申請を一覧画面にする</div>
        <div
          v-show="isOverView"
          @click="changeAllOverView(false)"
          class="mode-switch-btn off">全申請を入力画面にする</div>
      </div>

      <!-- 検索項目 -->
      <div class="contentsWidth mt-2">
        <div>
          <span class="filter-text">検索項目を表示する</span>
          <img
            @click="toggleFilter"
            class="filter-btn" src="@/assets/svg/accordion_btn.svg">
        </div>
        <div><hr class="filter-line mt-2 mb-0"></div>
        <div
          v-if="viewFilter"
          class="filter-area">
          <div class="flex flexCenter">
            <div class="search-middle inlineBlock search-space">
              <div>受付番号</div>
              <div>
                <b-form-input
                  v-model="filter.code"/>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>申請者氏名</div>
              <div>
                <b-form-input
                  v-model="filter.name"/>
              </div>
            </div>

            <div class="search-middle inlineBlock search-space">
              <div>合計点数</div>
              <div>
                <b-form-select
                  :options="criteriaOptions"
                  v-model="filter.score">
                  <template v-slot:first>
                    <option :value="null">---</option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <!-- <div class="search-middle inlineBlock search-space">
              <div>評価の完了</div>
              <div>
                <b-form-select
                  :options="doneOptions"
                  v-model="filter.done">
                  <template v-slot:first>
                    <option :value="null">---</option>
                  </template>
                </b-form-select>
              </div>
            </div> -->

          </div>
        </div>
      </div>

      <!-- 点数テーブル 1次審査でのみ表示-->
      <div class="contentsWidth mt-4"
        v-if="selectedStage && selectedStage.stage === 1 && criteria.length > 0">
        <div class="criteria-area">
          <div>
            <div class="inlineBlock criteria-score criteria-head">範囲</div>
            <div class="inlineBlock criteria-num criteria-head">基準件数</div>
            <div class="inlineBlock criteria-num criteria-head">判定件数</div>
            <div class="inlineBlock criteria-num criteria-head">判定過不足数</div>
          </div>
          <div
            v-for="(criterion, index) in criteria" :key="`criteria_${index}`">
            <div class="inlineBlock criteria-score">
              {{criterion.min_score}}点 ~ {{criterion.max_score}}点
            </div>
            <div class="inlineBlock criteria-num">{{criterion.num}}</div>
            <div class="inlineBlock criteria-num">{{criterionMatch(criterion.max_score, criterion.min_score)}}</div>
            <div class="inlineBlock criteria-num">{{criterionMatch(criterion.max_score, criterion.min_score) - criterion.num}}</div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-3">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <v-table
        v-show="!isInputMode"
        :doneSearch.sync="doneSearch"
        :appList.sync="appList"
        :perPage.sync="perPage"
        :filterdIndexList.sync="filterdIndexList"
        :currentPage.sync="currentPage"
        :sumScoreInputs.sync="sumScoreInputs"
        :commentItems.sync="items[constEval.COMMENT]"
        :inputs.sync="inputs"
        :viewUsecase.sync="viewUsecase"
        :evalIitems.sync="items"/> -->

      <div
        class="contentsWidth mt-2">
        <!-- 申請 -->
        <div
          v-for="(index) in viewFilterIndexList" :key="`app_${index}`"
          class="contentsWidth">
          <!-- 一覧表示 -->
          <div
            class="over-view-line flex px-2"
            v-if="appList[index].isOverView">
            <div class="over-view-name flex">
              <span class="over-view-label">{{usecaseLabel('NAME')}}</span>
              <span class="flex flex-tate over-view-value">
                {{appList[index].usecase[constUsecase.NAME][0].field_answer_text}}
                <span class="ml-1" v-if="appList[index].usecase[constUsecase.NAME][1]">
                  {{appList[index].usecase[constUsecase.NAME][1].field_answer_text}}
                </span>
              </span>
            </div>
            <div class="over-view-belong flex ml-1">
              <span class="over-view-label">所属</span>
              <span class="flex flex-tate over-view-value">
                {{belongText(appList[index])}}
              </span>
            </div>
            <div class="over-view-title flex ml-1">
              <span class="over-view-label">{{usecaseLabel('TITLE')}}</span>
              <span class="flex flex-tate over-view-value">
                <span class="over-ellipsis over-ellipsis-comment-title">
                  {{appList[index].usecase[constUsecase.TITLE][0].field_answer_text}}
                </span>
              </span>
            </div>
            <div
              v-if="items[constEval.SCORE] || items[constEval.ZAIDAN]"
              class="over-view-sum flex ml-1">
              <span class="over-view-label">合計点</span>
              <span class="flex flex-tate over-view-value">
                {{sumScoreInputs[appList[index].id]}}点
              </span>
            </div>
            <div
              v-else-if="items[constEval.RANK] && items[constEval.RANK].length > 0"
              class="over-view-sum flex ml-1">
              <span class="over-view-label">{{items[constEval.RANK][0].name}}</span>
              <span class="flex flex-tate over-view-value">
                {{inputs[appList[index].id][items[constEval.RANK][0].id].value}}位
              </span>
            </div>
            <div
              v-if="items[constEval.COMMENT] && items[constEval.COMMENT].length > 0"
              class="over-view-comment flex ml-1">
              <span class="over-view-label">{{items[constEval.COMMENT][0].name}}</span>
              <span class="flex flex-tate over-view-value">
                <span class="over-ellipsis over-ellipsis-comment">
                  {{inputs[appList[index].id][items[constEval.COMMENT][0].id].value}}
                </span>
              </span>
            </div>
            <div class="change-view-btn change-view-plus">
              <unicon name="plus-square"
                @click="changeOverView(index, false)"
                fill="#495057"
                ></unicon>
            </div>
          </div>

          <!-- 入力表示 -->
          <div
            v-if="!appList[index].isOverView"
            class="app-area pb-3 my-2">
            <div class="flex">
              <!-- 縮小表示ボタン -->
              <div class="change-view-btn change-view-minus">
                <unicon name="minus-square"
                  @click="changeOverView(index, true)"
                  fill="#495057"
                  ></unicon>
              </div>
              <!-- 左側 -->
              <div class="left-area inlineBlock mr-1px">
                <!-- 一段目 -->
                <div>
                  <div :class="[
                    'inlineBlock',
                    'mr-2px',
                    excelItemList[index] && excelItemList[index].isExcel ?
                      'view-middle-half' : 'view-middle'
                  ]">
                    <div class="field-name flex-tate pl-3">受付番号</div>
                    <div class="field-value flex-tate pl-3">{{appList[index].code}}</div>
                  </div>
                  <div :class="[
                    'inlineBlock',
                    excelItemList[index] && excelItemList[index].isExcel ?
                      'view-short-3 mr-2px' : 'view-short'
                  ]">
                    <div :class="[
                      'field-name',
                      'flex-tate',
                      excelItemList[index] && excelItemList[index].isExcel ?
                      'contents-center' : 'pl-3'
                    ]">申請書類</div>
                    <div class="field-value flex-tate flex-yoko pdf-wrap">
                      <span class="dummy"/><span class="pdf-btn" @click="exportPdf(appList[index].id)">PDF出力</span>
                    </div>
                  </div>
                  <div v-if="excelItemList[index] && excelItemList[index].isExcel" class="view-short view-short-2 inlineBlock">
                    <div class="field-name flex-tate contents-center">助成金使途明細</div>
                    <div class="field-value flex-tate flex-yoko pdf-wrap">
                      <span class="dummy"/><span class="pdf-btn" @click="downloadExcel(excelItemList[index], appList[index].user_id)">Excel出力</span>
                    </div>
                  </div>
                </div>
                <!-- 二段目 -->
                <div>
                  <div
                    :class="[
                    'inlineBlock',
                    !viewUsecase[constUsecase.SEX] && !viewUsecase[constUsecase.AGE] ?
                      'view-long' : 'view-middle mr-2px'
                    ]">
                    <div class="field-name flex-tate pl-3">{{usecaseLabel('NAME')}}（ふりがな）</div>
                    <div class="field-value flex-tate pl-3">
                      {{appList[index].usecase[constUsecase.NAME][0].field_answer_text}}
                      <span class="ml-1" v-if="appList[index].usecase[constUsecase.NAME][1]">
                        {{appList[index].usecase[constUsecase.NAME][1].field_answer_text}}
                      </span>
                      ({{appList[index].usecase[constUsecase.KANA][0].field_answer_text}}
                      <span class="ml-1" v-if="appList[index].usecase[constUsecase.KANA][1]">
                        {{appList[index].usecase[constUsecase.KANA][1].field_answer_text}})
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="viewUsecase[constUsecase.AGE]"
                    :class="[
                      'inlineBlock',
                      viewUsecase[constUsecase.SEX] ? 'view-minimum mr-2px' : 'view-short'
                    ]">
                    <div class="field-name flex-tate flex-yoko">年齢</div>
                    <div class="field-value flex-tate flex-yoko">
                      {{appList[index].usecase[constUsecase.AGE][0].field_answer_text}}歳
                    </div>
                  </div>
                  <div
                    v-if="viewUsecase[constUsecase.SEX]"
                    class="view-minimum inlineBlock">
                    <div class="field-name flex-tate flex-yoko">{{usecaseLabel('SEX')}}</div>
                    <div class="field-value flex-tate flex-yoko">
                      {{appList[index].usecase[constUsecase.SEX][0].choice_text}}
                      <span v-if="!appList[index].usecase[constUsecase.SEX][0].choice_text">-</span>
                    </div>
                  </div>
                </div>
                <!-- 三段目 -->
                <div>
                <!-- v-if="viewBelongs"> -->
                  <div class="view-long">
                    <div class="field-name flex-tate pl-3">申請者所属情報</div>
                    <div class="field-value flex-tate pl-3">
                      {{belongsText(appList[index])}}
                    </div>
                  </div>
                </div>
                <!-- 四段目 -->
                <div
                  v-if="viewUsecase[constUsecase.TITLE]">
                  <div class="view-long">
                    <div class="field-name flex-tate pl-3">{{usecaseLabel('TITLE')}}</div>
                    <div class="field-value flex-tate pl-3">
                      {{appList[index].usecase[constUsecase.TITLE][0].field_answer_text}}
                    </div>
                  </div>
                </div>
                <!-- 五段目 -->
                <div
                  v-if="viewUsecase[constUsecase.DETAIL]">
                  <div class="view-long">
                    <div class="field-name flex-tate pl-3">{{usecaseLabel('DETAIL')}}</div>
                    <div class="field-value flex-tate pl-3">
                      {{appList[index].usecase[constUsecase.DETAIL][0].field_answer_text}}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 右側 -->
              <div class="right-area inlineBlock">
                <!-- 五段目 -->
                <div>
                  <div class="view-long">
                    <div class="field-name flex-tate pl-3">評点</div>
                    <!-- 点数 -->
                    <div class="score-area pl-3 mt-2">
                      <div
                        v-for="(item, itemIndex) in items[constEval.SCORE]" :key="`score_${itemIndex}`"
                        class="score-wrap mt-2">
                        <div class="score-name">{{item.name}}</div>
                        <b-form-select
                          :disabled="isOutTerm || inputs[appList[index].id][item.id].disabled || appList[index].is_done === 1"
                          :value="inputs[appList[index].id][item.id].value"
                          @input="setScore(appList[index].id, item.id, $event)"
                          :options="scoreOptions[itemIndex]">
                          <template v-slot:first>
                            <option :value="0">---</option>
                          </template>
                        </b-form-select>
                      </div>
                      <!-- 調整点数 -->
                      <div
                        v-for="(item, itemIndex) in items[constEval.ADJUSTMENT]" :key="`adjustment_${itemIndex}`"
                        class="score-wrap mt-2">
                        <div class="score-name">{{item.name}}</div>
                        <b-form-select
                          :disabled="isOutTerm || inputs[appList[index].id][item.id].disabled || appList[index].is_done === 1"
                          :value="inputs[appList[index].id][item.id].value"
                          @input="setScore(appList[index].id, item.id, $event)"
                          @change="adjustmentAlert(inputs[appList[index].id], $event)"
                          :options="adjustScoreOptions[itemIndex]">
                        </b-form-select>
                      </div>
                      <!-- 事務局調整 -->
                      <div
                        v-for="(item, itemIndex) in items[constEval.ZAIDAN]" :key="`zaidan_${itemIndex}`"
                        class="score-wrap mt-2">
                        <div class="score-name">{{item.name}}</div>
                        <b-form-input
                          :value="inputs[appList[index].id][item.id].value"
                          :readonly="true"/>
                      </div>

                      <div
                        v-if="items[constEval.SCORE]"
                        class="score-wrap mt-2">
                        <div class="score-name">合計点</div>
                        <b-form-input
                          class="sum-input"
                          :value="sumScoreInputs[appList[index].id]"
                          :readonly="true"/>
                      </div>
                    <!-- 自動順位 -->
                     <div
                        class="score-wrap mt-2">
                        <div class="score-name">順位</div>
                        <b-form-input
                          class="sum-input"
                          :value="autoRankingList[appList[index].id]"
                          :readonly="true"/>
                      </div>
                    </div>
                  </div>
                  <!-- 順位 -->
                  <div class="score-area pl-3 mt-2">
                    <div
                      v-for="(item, itemIndex) in items[constEval.RANK]" :key="`rank_${itemIndex}`"
                      class="score-wrap">
                      <div class="score-name">{{item.name}}</div>
                      <b-form-select
                        :disabled="isOutTerm || inputs[appList[index].id][item.id].disabled || appList[index].is_done === 1"
                        @change="setRank(index, item.id, $event)"
                        :value="inputs[appList[index].id][item.id].value"
                        :options="filterdRankOption(item.id)">
                        <template v-slot:first>
                          <option :value="0">---</option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <!-- チェック -->
                  <div class="check-area pl-3 mt-3 mb-1">
                    <!-- <b-input-group -->
                    <div
                      v-for="(item, itemIndex) in items[constEval.CHECK]" :key="`check_${itemIndex}`"
                      class="check-wrap">
                      <div class="">
                        <b-input-group>
                        <span class="check-label">{{item.name}}</span>
                        <b-form-checkbox
                          :disabled="isOutTerm || appList[index].is_done === 1"
                          class="ml-3 eval-check"
                          :checked="inputs[appList[index].id][item.id].value"
                          @change="changeRigaiValue($event, appList[index].id, item.id)"
                          switches></b-form-checkbox>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                  <!-- コメント -->
                  <div class="score-area pl-3 mt-2">
                    <div v-if="items[constEval.ADJUSTMENT] && items[constEval.ADJUSTMENT].length > 0" class="score-name t-b-l">＊「調整点」で加点、減点をする場合には必ず「コメント」欄に理由を記入</div>
                    <div
                      v-for="(item, itemIndex) in items[constEval.COMMENT]" :key="`comment_${itemIndex}`"
                      class="comment-wrap">
                      <div class="score-name">{{item.name}}</div>
                      <b-form-textarea
                        :disabled="isOutTerm || inputs[appList[index].id][item.id].disabled || appList[index].is_done === 1"
                        rows="5"
                        max-rows="5"
                        :class="(items[constEval.ADJUSTMENT] && items[constEval.ADJUSTMENT].length > 0 && !inputs[appList[index].id][item.id].value && isSetedAdjustment(inputs[appList[index].id])) ? 'is-required' : ''"
                        :value="inputs[appList[index].id][item.id].value"
                        @input="setComment(appList[index].id, item.id, $event)"/>
                        <p class="error my-1"
                          v-show="items[constEval.ADJUSTMENT] && items[constEval.ADJUSTMENT].length > 0 && !inputs[appList[index].id][item.id].value && isSetedAdjustment(inputs[appList[index].id])">入力してください。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="showBackComments" class="ml-2 mr-3">
              <div :data-history="showHistoryComments[index]">
                <span class="filter-text">前の審査段階のコメントを表示する</span>
                <img
                  @click="getHistoryComments(appList[index].id, index)"
                  class="filter-btn" src="@/assets/svg/accordion_btn.svg">
              </div>
              <div v-show="showHistoryComments[index]">
                <b-table striped hover
                  id='fieldTable'
                  table-class="cmsHistoryCommentsTable"
                  thead-class="tableHead"
                  tbody-tr-class="dataWrap"
                  :items="historyComments[index]"
                  :fields="fields">
                  <template v-slot:cell(assignName)="row">
                    {{row.item.sei}} {{row.item.mei}}
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="totalRows > 0"
        class="contentsWidth mt-3">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
        <p class="mb-1 red-text">「提出」押下後は修正変更ができなくなります。確認の上、後刻提出の場合は「一時保存」ボタンを押下してください。</p>
      </div>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
          class="btn btn-lg bold"
          to="/cms/top">トップに戻る</b-button>
        <b-button
          v-if="!isOutTerm && !allDone"
          @click="save(false)"
          class="btn btn-lg bold ml-2"
          variant="info"
        >一時保存</b-button>
        <b-button
          v-if="!isOutTerm && !allDone"
          @click="save(true)"
          class="btn btn-lg bold ml-2"
          variant="info"
        >提出</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportCsv()"
          >CSV出力</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import api from '@/modules/api';
import download from '@/modules/download';
import CONST_USECASE from '@/constants/usecase';
import CONST_EVAL from '@/constants/evaluation';
// import table from '@/components/cms/evaluation/Table.vue';

export default {
  name: 'CmsApplicationsEvaluationList',
  components: {
    // 'v-table': table,
  },
  data() {
    return {
      isOverView: false,
      statusList: [
        { value: 0, text: '未' },
        { value: 1, text: '済' },
      ],
      yearList: [],
      rawApptypeData: {},
      viewUsecase: {},
      appList: [],
      // 点数・スコア入力欄
      inputs: {},
      // 合計点入力欄
      sumScoreInputs: {},
      // 順位のオプション達
      rankOptions: {},
      items: [],
      criteria: [],
      errMessages: {},
      perPage: 50,
      currentPage: 1,
      constUsecase: CONST_USECASE.USECASE,
      constEval: CONST_EVAL.EVALUATION_TYPE,
      search: {
        year: null,
        apptype: null,
        stageId: null,
      },
      viewFilter: false,
      filter: {
        code: '',
        name: '',
        score: null,
        // done: null,
      },
      allDone: true,
      init: true,
      showHistoryComments: {},
      fields: [
        {
          key: 'assignName',
          label: '審査委員氏名',
          sortable: false,
          class: 'assignName',
        },
        {
          key: 'historyComment',
          label: 'コメント',
          sortable: false,
          class: 'historyComment',
        },
      ],
      historyComments: [],
      autoRankingList: {},
      rankingUseData: [],
      excelItemList: {},
      pdfErr: null,
      stakeholders: [],
    };
  },
  methods: {
    async adjustmentAlert(input, val) {
      if (val !== 0) {
        let isErr = false;
        Object.keys(input).forEach((key) => {
          const item = input[key];
          const intType = Number(item.type);
          if (intType === this.constEval.COMMENT && (item.value === null || item.value === '')) {
            isErr = true;
          }
        });
        if (isErr) {
          this.$store.dispatch('page/onAlerting');
          await this.alert('「調整点」で加点、減点をする場合には必ず「コメント」欄に理由を記入してください。', false);
          this.$store.dispatch('page/offAlerting');
        }
      }
    },
    async initFetch() {
      const param = {
        year: this.search.year,
        apptype: this.search.apptype,
        stageId: this.search.stageId,
      };
      const promiseFuncs = [
        api.send('/api/cms/evaluation/list', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      if (!responses) {
        return;
      }
      this.yearList = responses[0].data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.rawApptypeData = responses[0].data.apptypeData;
    },
    async fetchAppList() {
      this.appList = [];
      this.items = [];
      this.criteria = [];
      this.viewUsecase = {};
      this.sumScoreInputs = {};
      this.showHistoryComments = {};
      this.stakeholders = [];
      this.allDone = true;
      if (!this.hasSelect) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptype: this.search.apptype,
        stageId: this.search.stageId,
      };
      const response = await api.send('/api/cms/evaluation/applications/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.appList = response.data.appList;
      // すべての申請に一覧表示フラグを追加
      // すべて入力完了しているかチェック
      Object.keys(this.appList).forEach((key) => {
        this.$set(this.appList[key], 'isOverView', false);
        if (!this.appList[key].is_done) {
          this.allDone = false;
        }
      });
      this.items = response.data.items;
      this.criteria = response.data.criteria;
      this.viewUsecase = response.data.viewUsecase;
      this.excelItemList = response.data.excelItemList;
      this.setDbInputs();
    },
    async save(isDone) {
      if (isDone) {
        const msg = '提出後、修正変更はできません。提出してもよろしいですか？';
        if (!await this.confirm(msg)) {
          return;
        }
      }
      this.errMessages = {};
      this.$store.dispatch('page/onWaiting');
      const param = {
        isDone,
        stageId: this.search.stageId,
        evalInputs: this.inputs,
      };
      const response = await api.send('/api/cms/evaluation/save', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            if (err.response.data.messages.comment) {
              await this.alert(err.response.data.messages.comment, false);
            } else {
              await this.alert('入力内容に誤りがありました。修正してください。', false);
            }
            this.errMessages = err.response.data.messages;
          } else {
            await this.alert('登録に失敗しました。', false);
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('登録しました。', false);
      this.$store.dispatch('page/offWaiting');
      this.fetchAppList();
    },
    setDbInputs() {
      this.rankOptions = {};
      const baseInputs = {};
      // データ入力欄作成
      Object.keys(this.items).forEach((type) => {
        let initValue;
        const intType = Number(type);
        // 入力項目定義にない値は入れ物を作らない
        if (!this.items[intType]) {
          return;
        }
        // 点数&事務局調整の場合は初期値を0
        if (intType === this.constEval.SCORE || intType === this.constEval.ZAIDAN || intType === this.constEval.RANK || intType === this.constEval.ADJUSTMENT) {
          initValue = 0;
        } else if (intType === this.constEval.CHECK) {
          initValue = false;
        } else {
          initValue = '';
        }
        const typeItemList = this.items[type];
        typeItemList.forEach((item) => {
          // 入力値の入れ物と初期値設定
          baseInputs[item.id] = {
            type,
            value: initValue,
          };
          // 順位の場合は、順位のオプションデータを作成
          if (intType === this.constEval.RANK) {
            const options = {};
            // todo: .map等でできる
            for (let i = 1; i <= item.param; i += 1) {
              const option = {
                value: i,
                text: `${i}位`,
                disabled: false,
              };
              options[i] = option;
            }
            this.rankOptions[item.id] = options;
          }
        });
      });
      // 各申請に対してデータ入力欄作成
      const inputs = {};
      this.rankingUseData = [];
      Object.keys(this.appList).forEach((appId) => {
        const app = this.appList[appId];
        inputs[appId] = cloneDeep(baseInputs);
        let initSum = 0;
        Object.keys(app.evals).forEach((itemId) => {
          // 値があれば、入力値に追加する
          if (app.evals[itemId]) {
            const type = Number(inputs[appId][itemId].type);
            // 通常はそのまま値を入れるが、チェックボックスは1/0をboolに置き換える
            if (type === this.constEval.CHECK) {
              inputs[appId][itemId].value = app.evals[itemId] === 1;
              if (app.evals[itemId] === 1) {
                this.stakeholders.push(Number(appId));
              } else {
                this.stakeholders.pop(Number(appId));
              }
            } else {
              inputs[appId][itemId].value = app.evals[itemId];
            }
            // ランクの場合は、オプションの選択不可処理を行う
            if (type === this.constEval.RANK && app.evals[itemId] !== 0) {
              this.$set(this.rankOptions[itemId][app.evals[itemId]], 'disabled', true);
            }
            // 点数と事務局調整は合計点の計算を行う
            if (type === this.constEval.SCORE || type === this.constEval.ZAIDAN || type === this.constEval.ADJUSTMENT) {
              initSum += app.evals[itemId];
            }
          }
        });
        this.sumScoreInputs[appId] = initSum;
        this.rankingUseData.push({ id: appId, total: initSum });
      });
      this.inputs = inputs;
      this.setAutoRanking();
    },
    // 点数の入力
    setScore(appId, itemId, value) {
      this.$set(this.inputs[appId][itemId], 'value', value);
      // すべてのSCOREが入力されているかチェック
      let allScoreInput = 1;
      // 合計点の計算
      let sum = 0;
      Object.keys(this.inputs[appId]).forEach((itemKey) => {
        const type = Number(this.inputs[appId][itemKey].type);
        if (type === this.constEval.SCORE || type === this.constEval.ZAIDAN || type === this.constEval.ADJUSTMENT) {
          sum += this.inputs[appId][itemKey].value;
        }
        // allScoreInputがまだtrueのとき、scoreが1つでも選択されていないかどうかチェックする
        if (allScoreInput && type === this.constEval.SCORE && this.inputs[appId][itemKey].value === 0) {
          allScoreInput = 0;
        }
      });
      this.$set(this.sumScoreInputs, appId, sum);
      this.rankingUseData.some((data, key) => {
        if (String(data.id) === String(appId)) {
          this.rankingUseData[key].total = sum;
          return true;
        }
        if (this.rankingUseData.length === key + 1) {
          this.rankingUseData.push({ id: appId, total: sum });
          return true;
        }
        return false;
      });
      this.setAutoRanking();
    },
    setAjustScore(appId, itemId, value) {
      this.$set(this.inputs[appId][itemId], 'value', value);
    },
    setFilter(key, value) {
      this.$set(this.search, key, value);
    },
    // コメント
    setComment(appId, itemId, value) {
      this.$set(this.inputs[appId][itemId], 'value', value);
    },
    setRank(appId, itemId, value) {
      // 古い値のdisabledを消す
      const oldValue = this.inputs[appId][itemId].value;
      if (oldValue !== 0) {
        this.$set(this.rankOptions[itemId][oldValue], 'disabled', false);
      }
      // 今回選択した値を選択不可にする
      if (value !== 0) {
        this.$set(this.rankOptions[itemId][value], 'disabled', true);
      }
      // this.rankOptions[itemId][value].disabled = true;
      // 値をセットする
      this.$set(this.inputs[appId][itemId], 'value', value);
    },
    // 助成プログラムの検索条件変更時、審査段階のフィルタリングを初期化する
    resetFilter(apptype, stage) {
      if (this.init) {
        return;
      }
      // リセット
      if (apptype) {
        this.$set(this.search, 'apptype', null);
      }
      if (stage) {
        this.$set(this.search, 'stageId', null);
      }
      this.appList = [];
      this.items = [];
      this.criteria = [];
      this.viewUsecase = [];
    },
    async exportPdf(appId) {
      this.$store.dispatch('page/onWaiting');
      this.pdfErr = null;
      const params = {
        appId,
        stageId: this.search.stageId,
        kind: 1,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/list/export/pdf', params, requireBlob)
        .catch(async (err) => {
          const error = JSON.parse(await err.response.data.text());
          if (error && error.msg) {
            this.pdfErr = error.msg;
          }
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        if (this.pdfErr) {
          await this.alert(this.pdfErr);
        } else {
          await this.alert('ファイルのダウンロードに失敗しました。');
        }
        return;
      }
      const ymd = moment().format('YYYMMDD');
      const fileName = `${ymd}_${this.rawApptypeData[this.search.apptype].name}.pdf`;
      download.blob(response.data, fileName);
    },
    async downloadExcel(params, userId) {
      const fileName = params.file_path;
      const url = '/api/cms/evaluation/download/uploadedFile';
      const param = {
        application_id: params.application_id,
        field_id: params.field_id,
        option_id: params.option_id,
        answer_no: params.answer_no,
        stageId: this.search.stageId,
        user_id: userId,
      };
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    // eslint-disable-next-line
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (key === 'score') {
        if (sortDesc && aRow[key] === null) {
          return -1;
        }
        if (sortDesc && bRow[key] === null) {
          return +1;
        }
      }
      return false;
    },
    toggleFilter() {
      this.viewFilter = !this.viewFilter;
    },
    toggleHistoryCommentsFilter(key) {
      if (typeof this.showHistoryComments[key] === 'undefined') {
        this.$set(this.showHistoryComments, key, false);
      }
      this.showHistoryComments[key] = !this.showHistoryComments[key];
    },
    checkSumScore(appId, criterion) {
      const okMin = criterion.min_score <= this.sumScoreInputs[appId];
      const okMax = this.sumScoreInputs[appId] <= criterion.max_score;
      return okMin && okMax;
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      // n次のnを取得
      const stageData = this.stageList.find(data => data.value === this.search.stageId);
      const params = {
        apptype: this.search.apptype,
        stage: stageData.no,
        backStage: this.getBackStage,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/export/my/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.search.apptype);
      const fileName = `${ymd}_${apptype.text}_${stageData.no}次.csv`;
      download.csvUtf(response.data, fileName);
    },
    // 縮小表示、拡大表示切り替え
    changeOverView(index, bool) {
      this.$set(this.appList[index], 'isOverView', bool);
    },
    changeAllOverView(bool) {
      this.isOverView = bool;
      Object.keys(this.appList).forEach((index) => {
        this.$set(this.appList[index], 'isOverView', bool);
      });
    },
    changeRigaiValue(value, appId, thisItemId) {
      this.$set(this.inputs[appId][thisItemId], 'value', value);
      if (value) {
        this.stakeholders.push(Number(appId));
      } else {
        this.stakeholders.pop(Number(appId));
      }
      Object.keys(this.inputs[appId]).forEach((itemId) => {
        if (value) {
          const item = this.inputs[appId][itemId];
          const intType = Number(item.type);
          let initValue;
          // 入力項目定義にない値は入れ物を作らない
          if (!this.items[intType] || thisItemId === Number(itemId)) {
            return;
          }
          // 点数&事務局調整の場合は初期値を0
          if (intType === this.constEval.SCORE || intType === this.constEval.ZAIDAN || intType === this.constEval.RANK || intType === this.constEval.ADJUSTMENT) {
            initValue = 0;
          // } else if (intType === this.constEval.CHECK) {
          //   initValue = false;
          } else {
            initValue = '';
          }
          // 初期に変更し、disabled化
          if (initValue === 0 || initValue === '') {
            this.$set(this.inputs[appId][itemId], 'value', initValue);
          }
        }
        this.$set(this.inputs[appId][itemId], 'disabled', value);
      });
    },
    async getHistoryComments(appId, key) {
      // コメント表示エリアの非表示or表示
      if (this.showHistoryComments[key]) {
        this.toggleHistoryCommentsFilter(key);
        return;
      }
      // コメント取得
      this.$store.dispatch('page/onWaiting');
      // 表示中の審査段階のindex取得
      let selectedStageIndex = 0;
      this.stageList.forEach((data, index) => {
        if (this.search.stageId === data.value) {
          selectedStageIndex = index;
        }
      });
      // 取得してくるコメント（前の段階のコメン）のindex
      let targetStageIndex = 0;
      if (selectedStageIndex > 0) {
        targetStageIndex = selectedStageIndex - 1;
      }
      const params = {
        applicationId: appId,
        stage: this.stageList[targetStageIndex].value,
      };
      const response = await api.send('/api/cms/evaluation/getPrevCommentsHitory', params)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('前の審査段階のコメント取得に失敗しました。');
        return;
      }
      this.$set(this.historyComments, key, response.data.prevCommentsData);
      this.$store.dispatch('page/offWaiting');
      this.toggleHistoryCommentsFilter(key);
    },
    setAutoRanking() {
      // 順位自動計算
      const sortScoreData = this.rankingUseData.sort((p1, p2) => {
        return p2.total - p1.total;
      });
      let backData = {};
      sortScoreData.forEach((totalData, key) => {
        let setRank = null;
        if (totalData.total > 0) {
          if (key === 0) {
            setRank = key + 1;
          } else if (backData && backData.total === totalData.total) {
            setRank = backData.rank;
          } else if (backData) {
            setRank = key + 1;
          } else {
            setRank = 1;
          }
          backData = { id: totalData.id, total: totalData.total, rank: setRank };
        }
        this.$set(this.autoRankingList, totalData.id, setRank);
      });
    },
  },
  computed: {
    isSetedAdjustment() {
      return (input) => {
        let isReturnBool = false;
        this.items[this.constEval.ADJUSTMENT].forEach((item) => {
          if (input[item.id].value && input[item.id].value !== 0) {
            isReturnBool = true;
          }
        });
        return isReturnBool;
      };
    },
    getBackStage() {
      // 表示中の審査段階のindex取得
      let selectedStageIndex = 0;
      this.stageList.forEach((data, index) => {
        if (this.search.stageId === data.value) {
          selectedStageIndex = index;
        }
      });
      // 取得してくるコメント（前の段階のコメン）のindex
      if (selectedStageIndex > 0) {
        const targetStageIndex = selectedStageIndex - 1;
        return this.stageList[targetStageIndex].value;
      }
      return null;
    },
    showBackComments() {
      let selectedStageIndex = 0;
      this.stageList.forEach((data, index) => {
        if (this.search.stageId === data.value) {
          selectedStageIndex = index;
        }
      });
      return selectedStageIndex > 0;
    },
    defaultStageText() {
      if (this.stageList.length <= 0 && !this.search.apptype) {
        return '助成プログラムを選択してください';
      }
      if (this.stageList.length <= 0 && this.search.apptype) {
        return '審査段階が登録されていません';
      }
      return '-- 未選択 --';
    },
    apptypeList() {
      const apptypeList = [];
      Object.keys(this.rawApptypeData).forEach((key) => {
        if (this.rawApptypeData[key].year === Number(this.search.year)) {
          const param = {
            value: this.rawApptypeData[key].id,
            text: this.rawApptypeData[key].name,
          };
          apptypeList.push(param);
        }
      });
      return apptypeList;
    },
    stageList() {
      const apptypeId = this.search.apptype;
      if (apptypeId === null || !this.rawApptypeData[apptypeId]) {
        return [];
      }
      return this.rawApptypeData[apptypeId].evaluation_stages.map((stage) => {
        return {
          value: stage.id,
          text: `${stage.stage}次`,
          no: stage.stage,
        };
      });
    },
    hasSelect() {
      const okYear = this.search.year !== null;
      const okApptype = this.search.apptype !== null;
      const okStage = this.search.stageId !== null;
      return okYear && okApptype && okStage;
    },
    pageOptions() {
      return [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
        { value: 20, text: 20 },
        { value: 25, text: 25 },
        { value: 50, text: 50 },
      ];
    },
    noDataMsg() {
      if (!this.hasSelect) {
        return '年度、助成プログラム、審査段階を選択してください。';
      }
      return '条件に一致するデータがありません。';
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    adjustScoreOptions() {
      const adjustScoreOptions = [];
      this.items[7].forEach((item) => {
        const array = [];
        [...Array(item.param).keys()].forEach((i) => {
          const setVal = i - item.param;
          array.push({ value: setVal, text: setVal });
        });
        array.push({ value: 0, text: '---' });
        [...Array(item.param).keys()].forEach((i) => {
          const setVal = i + 1;
          array.push({ value: setVal, text: setVal });
        });
        adjustScoreOptions.push(array);
      });
      return adjustScoreOptions;
    },
    scoreOptions() {
      const scoreOptions = [];
      this.items[1].forEach((item) => {
        // eslint-disable-next-line
        const array = [...Array(item.param).keys()].map(i => i += 1);
        scoreOptions.push(array);
      });
      return scoreOptions;
    },
    usecaseLabel() {
      return (constKey) => {
        const key = this.constUsecase[constKey];
        if (this.viewUsecase[key]) {
          return this.viewUsecase[key].label;
        }
        return null;
      };
    },
    viewBelongs() {
      return !!(this.viewUsecase[this.constUsecase.BELONGS] || this.viewUsecase[this.constUsecase.BELONGS_2] || this.viewUsecase[this.constUsecase.POSITION]);
    },
    belongText() {
      return (app) => {
        let text = '';
        if (app.usecase[this.constUsecase.BELONGS]) {
          text += app.usecase[this.constUsecase.BELONGS][0].field_answer_text;
        }
        return text;
      };
    },
    belongsText() {
      return (app) => {
        let text = '';
        if (app.usecase[this.constUsecase.BELONGS]) {
          text += app.usecase[this.constUsecase.BELONGS][0].field_answer_text;
        }
        if (app.usecase[this.constUsecase.BELONGS_2]) {
          text += ' ';
          text += app.usecase[this.constUsecase.BELONGS_2][0].field_answer_text;
        }
        if (app.usecase[this.constUsecase.POSITION]) {
          text += ' ';
          text += app.usecase[this.constUsecase.POSITION][0].field_answer_text;
        }
        return text;
      };
    },
    filterdIndexList() {
      const indexList = [];
      // 基準点の入力があれば、該当の点数を取得
      let criterion = {};
      if (this.filter.score !== null) {
        criterion = this.criteria[this.filter.score];
      }
      Object.keys(this.appList).forEach((appId) => {
        const app = this.appList[appId];
        let name = app.usecase[this.constUsecase.NAME][0].field_answer_text;
        if (app.usecase[this.constUsecase.NAME][1]) {
          name += ` ${app.usecase[this.constUsecase.NAME][1].field_answer_text}`;
        }
        const okCode = this.filter.code === '' || (app.code && app.code.includes(this.filter.code));
        const okName = this.filter.name === '' || name.includes(this.filter.name);
        // const okDone = this.filter.done === null || this.filter.done === app.is_done;
        const okScore = this.filter.score === null || this.checkSumScore(appId, criterion);
        if (okCode && okName && okScore) {
          indexList.push(appId);
        }
      });
      return indexList;
    },
    totalRows() {
      return this.filterdIndexList.length;
    },
    viewFilterIndexList() {
      const from = (this.currentPage - 1) * this.perPage;
      const to = this.currentPage * this.perPage;
      return this.filterdIndexList.slice(from, to);
    },
    criterionMatch() {
      return (min, max) => {
        let count = 0;
        Object.keys(this.sumScoreInputs).forEach((appId) => {
          if ((typeof this.autoRankingList[appId] !== 'undefined' && this.autoRankingList[appId]) || this.stakeholders.includes(Number(appId))) {
            if (min <= this.sumScoreInputs[appId] && this.sumScoreInputs[appId] <= max) {
              count += 1;
            }
          }
        });
        return count;
      };
    },
    doneOptions() {
      return [
        { value: 0, text: '未' },
        { value: 1, text: '済' },
      ];
    },
    criteriaOptions() {
      const options = [];
      this.criteria.forEach((criterion, index) => {
        const option = {
          value: index,
          text: `${criterion.min_score}点~${criterion.max_score}点`,
        };
        options.push(option);
      });
      return options;
    },
    filterdRankOption() {
      return (itemId) => {
        const options = [];
        Object.keys(this.rankOptions[itemId]).forEach((key) => {
          options.push(this.rankOptions[itemId][key]);
        });
        return options;
      };
    },
    doneSearch() {
      return this.search.year !== null && this.search.apptype !== null && this.search.stageId !== null;
    },
    selectedStage() {
      const apptypeId = this.search.apptype;
      if (apptypeId === null || !this.rawApptypeData[apptypeId]) {
        return null;
      }
      return this.rawApptypeData[apptypeId].evaluation_stages.find((stage) => {
        return stage.id === this.search.stageId;
      });
    },
    isOutTerm() {
      if (!this.selectedStage) {
        return false;
      }
      return !moment().isBetween(this.selectedStage.from, this.selectedStage.to);
    },
    outTermText() {
      if (!this.isOutTerm) {
        return {};
      }
      return {
        from: moment(this.selectedStage.from).format('YYYY/MM/DD HH:mm'),
        to: moment(this.selectedStage.to).format('YYYY/MM/DD HH:mm'),
      };
    },
    canExportCsv() {
      return this.search.apptype && this.search.stageId && this.totalRows > 0;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    const { year, apptypeId, stageId } = this.$route.params;
    if (year && apptypeId && stageId) {
      this.$set(this.search, 'year', Number(year));
      this.$set(this.search, 'apptype', Number(apptypeId));
      this.$set(this.search, 'stageId', Number(stageId));
      await this.fetchAppList();
    }
    this.init = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .eval-check>.custom-control-label::after, .eval-check>.custom-control-label::before {
    width: 20px !important;
    height: 20px !important;
  }
  table.cmsHistoryCommentsTable thead tr{
    text-align: center;
  }
  table.cmsHistoryCommentsTable .assignName{
    text-align: center;
    width: 250px;
  }
</style>

<style scoped>
  .view-middle-half{
    width: 305px !important;
  }
  .view-short-2{
    width: 130px !important;
  }
  .view-short-3{
    width: 100px !important;
  }
  .contents-center {
    justify-content: center;
  }
  .search-area input, .search-area select, .filter-area input, .filter-area select {
    height: 50px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .search-space {
    margin-right: 15px;
  }
  .search-middle {
    width: 300px;
  }
  .search-middle-long {
    width: 340px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }
  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .mr-2px {
    margin-right: 2px;
  }

  .app-area {
    position: relative;
    background-color: #FFF;
  }
  .left-area {
    width: 539px;
  }
  .right-area {
    width: 539px;
    margin-left: 2px;
  }
  .field-name {
    background-color: #DDD;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    font-weight: bold;
  }
  .field-value {
    padding: 10px 0;
    min-height: 45px;
    display: -webkit-flex;
    display: flex;
  }
  .flex-tate {
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
  }
  .flex-yoko {
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
  }

  .view-long {
    width: 538px;
  }
  .view-middle {
    width: 408px;
  }
  .view-short {
    width: 129px;
  }
  .view-minimum {
    width: 63px;
  }

  .score-wrap {
    width: 160px;
    margin-right: 10px;
    display: inline-block;
  }
  .score-wrap>select, .score-wrap>input {
    height: 50px;
  }
  .score-name {
    font-size: 12px;
  }
  .comment-wrap {
    width: 500px;
  }

  /* 基準件数 */
  .criteria-area {
    background-color: #fff;
    width: 654px;
  }
  .criteria-head {
    background-color: #6E6E6E;
    color: #fff;
    font-weight: bold;
  }
  .criteria-score {
    width: 200px;
    height: 40px;
    margin-right: 1px;
    padding: 8px 0 8px 15px;
  }
  .criteria-num {
    width: 150px;
    height: 40px;
    margin-right: 1px;
    padding: 8px 0 8px 15px;
  }

  .filter-area {
    background: #FFF;
    padding: 10px;
    margin-bottom: 30px;
  }
  .filter-line {
    color:#293CB0 !important;
    border-width: 1px;
  }
  .filter-text {
    color: #293CB0;
    margin-right: 10px;
    font-weight: bold;
  }
  .filter-btn {
    margin-bottom: 3px;
  }
  .filter-btn:hover {
    cursor: pointer;
  }

  /* チェックボックス */
  .check-label {
    margin-top: 2px;
  }

  .pdf-wrap {
    position: relative;
    height: 45px;
    padding: 0px !important;
  }
  .pdf-btn {
    display: block;
    height: 30px;
    color :#293CB0;
    border: 2px solid #293CB0;
    font-size: 14px;
    font-weight: bold;
    padding: 4px;
    border-radius: 5px;
  }
  .pdf-btn:hover {
    cursor: pointer;
  }

  .sum-input {
    background-color: #6E6E6E !important;
    color: #fff
  }

  .mode-switch-btn {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color :#293CB0;
    border: 2px solid #293CB0;
    font-size: 16px;
    font-weight: bold;
    padding: 4px;
    border-radius: 5px;
  }
  .off {
    background-color: #DDD;
  }
  .on {
    background-color: #FFF;
  }
  .mode-switch-btn:hover {
    cursor: pointer;
  }

  /* なぜかこれがないとデザインが崩れる不思議高さ */
  .dummy {
    height: 10px;
  }

  .notice {
    color: #dc3545;
  }

  .over-view-line {
    /* border: solid 1px #000; */
    border-bottom: solid 1px #CCC;
    min-height: 40px;
    background-color: #FFF;
    position: relative;
  }

  .over-view-label {
    font-size: 8px;
    min-width: 30px;
    position: absolute;
  }

  .over-view-value {
    margin-top: 15px;
    margin-left: 10px;
  }

  .over-view-name {
    width: 200px;
    max-width: 200px;
  }

  .over-view-belong {
    width: 200px;
    max-width: 200px;
  }

  .over-view-title {
    width: 250px;
    max-width: 250px;
  }

  .over-view-sum {
    width: 60px;
    max-width: 60px;
  }
  .over-view-comment {
    width: 300px;
    max-width: 300px;
  }

  .over-ellipsis {
    width: 250px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  .over-ellipsis-title {
    width: 250px;
    max-width: 250px;
  }
  .over-ellipsis-comment {
    width: 300px;
    max-width: 300px;
  }

  .change-view-btn {
    width: 30px;
    position: absolute;
    right: 1%;

  }
  .change-view-btn:hover {
    cursor: pointer;
  }
  .change-view-plus {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
  .change-view-minus {
    top: 1%;
  }
  .t-b-l {
    color: #dc3545;
    font-weight: bold;
  }
  .is-required {
    border-color: #dc3545!important;
    background: #ffe9eb;
  }
  .error{
    font-size: 80%;
    color: #dc3545;
  }
</style>
